import "./horizontal_scroll_style.css";

const list_1 = document.querySelector("#hs_list_1");
const items_1 = Array.from(document.querySelectorAll(
    '#hs_item_10, #hs_item_11, #hs_item_12, #hs_item_13, #hs_item_14, #hs_item_15, #hs_item_16, #hs_item_17,' +
    '#hs_item_18, #hs_item_19, #hs_item_110, #hs_item_111, #hs_item_112, #hs_item_113, #hs_item_114, #hs_item_115'));
const indicators_1 = Array.from(document.querySelectorAll(
    '#hs_indicator_10, #hs_indicator_11, #hs_indicator_12, #hs_indicator_13, #hs_indicator_14, #hs_indicator_15' +
    '#hs_indicator_16, #hs_indicator_17, #hs_indicator_18, #hs_indicator_19, #hs_indicator_110, #hs_indicator_111' +
    '#hs_indicator_112, #hs_indicator_113, #hs_indicator_114, #hs_indicator_115'));
const imgs_1 = Array.from(document.querySelectorAll(
    '#hs_1_img_0, #hs_1_img_1, #hs_1_img_2, #hs_1_img_3, #hs_1_img_4, #hs_1_img_5, #hs_1_img_6, #hs_1_img_7, ' +
    '#hs_1_img_8, #hs_1_img_9, #hs_1_img_10, #hs_1_img_11, #hs_1_img_12, #hs_1_img_13, #hs_1_img_14, #hs_1_img_0, #hs_1_img_15'
));
const prev_1 = document.querySelector("#hs_prev_1");
const next_1 = document.querySelector("#hs_next_1");
const state_1 = { active: 0 };

var imgs_loaded = false;

function checkImgs() {
    var loaded = [];
    imgs_1.forEach((imgs) => {
        loaded.push(imgs.complete && imgs.naturalHeight !== 0);
    })
    if (loaded.every(Boolean)) {
        imgs_loaded = true;
        console.log("!");
    }
}

checkImgs()

function onIntersectionObserved_1(entries) {
    entries.forEach(entry => {
        if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
            if (imgs_loaded) {
                state_1.active = items_1.indexOf(entry.target);
            } else {
                checkImgs()
                if (imgs_loaded) {
                    state_1.active = items_1.indexOf(entry.target);
                }
            }
            indicators_1.forEach((indicator, i) => {
                indicator.classList.toggle("active", i === state_1.active);
            });
        }
    });
}

const observer_1 = new IntersectionObserver(onIntersectionObserved_1, {
    root: list_1,
    threshold: 0.5
});

items_1.forEach(item => {
    observer_1.observe(item);
});

indicators_1.forEach((x, i) =>
    x.addEventListener("click", () =>
        items_1[i].scrollIntoView({
            block: "nearest",
            // inline: "start",
            behavior: "smooth"
        })
    )
);

prev_1.addEventListener("click", () => {
    items_1[state_1.active - 1]?.scrollIntoView({
        block: "nearest",
        // inline: "start",
        behavior: "smooth"
    });
});

next_1.addEventListener("click", () => {
    items_1[state_1.active + 1]?.scrollIntoView({
        block: "nearest",
        // inline: "start",
        behavior: "smooth"
    });
});